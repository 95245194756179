import DfBrand from "@/vue/domain/brand/df-brand";
import DfStore from "@/vue/domain/store/df-store";
import Utils from "@/vue/infrastructure/utils/helpers";
import { Component, Vue } from "vue-property-decorator";
import DfContent from "@/vue/domain/content/df-content";
const DfSectionLinksComponent = () =>
  Utils.externalComponent2("df-section-links");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfRecipeCardComponent = () => Utils.externalComponent2("df-recipe-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfRecipeCard: DfRecipeCardComponent,
    DfStoreCard: DfStoreCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
  },
})
export default class DfPageRecipesComponent extends Vue {
  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get recipes(): Array<DfContent> {
    const storeNews: Array<DfContent> = this.$store.getters.storeContents
      .filter((content: DfContent) => content.type.code === "RCP");

    const brandNews: Array<DfContent> = this.$store.getters.brandContents
      .filter((content: DfContent) => content.type.code === "RCP");

    const news: Array<DfContent> = [...storeNews, ...brandNews].sort((contentA, contentB) =>
      contentA.priority <= contentB.priority ? 1 : -1
    );

    return news;
  }

  get showBreadcrumb(): boolean {
    return Utils.showBreadcrumb();
  }

  private goToRecipe(recipe: DfContent) {
    this.$router.push({ name: "recipe", params: { storeAlias: this.currentStore.alias, recipeAlias: recipe.alias } });
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(
      `${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(
        this.currentStore
      )}`,
      "_blank"
    );
  }
}
